<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <span class="mb-2">{{ userData.first_name }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Full Name:</span>
              <span class="text--secondary">{{ userData.first_name }} {{ userData.last_name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Mobile Number:</span>
              <span class="text--secondary">{{ userData.mobile_number }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ userData.email }}</span>
            </v-list-item>

            <v-list-item
              v-if="userData.company"
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Company:</span>
              <span class="text--secondary">{{ userData.companies[0].company_name }}</span>
            </v-list-item>

            <v-list-item
              v-if="userData.employee_number"
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Employee Number:</span>
              <span class="text--secondary"> {{ userData.companies[0].employee_number }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Points:</span>
              <span class="text--secondary"> {{ userData.points }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Date of joining:</span>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                >
                  {{ moment(userData.created_at) }}
                </v-chip>
              </span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Verified:</span>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="resolveUserStatusVariant(userData.is_verified)"
                >
                  {{ resolveUserVerified(userData.is_verified) }}
                </v-chip>
              </span>
            </v-list-item>

            <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">Address:</span>
            <span class="text--secondary">
                <div v-for="address in userData.address">
                   <ul>
                    <li> <b>title</b>:({{address.title}}) - <b>address</b>:({{address.street_name}}) - street name:({{address.street_name}}) - building number:({{address.building_number}})</li>
                   </ul>
                </div>
            </span>
          </v-list-item>

          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    
  </v-row>
</template>

<script>
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useUsersList from '../../user-list/useUsersList'
import UserBioEdit from './UserBioEdit.vue'
import moment from 'moment'

export default {
  components: {
    UserBioEdit,
  },
  methods: {
    moment: date => {
      return moment(date).locale('JO').format('YYYY-MM-DD')
    },
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserVerified, resolveUserStatusVariant } = useUsersList()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }
    const userBreakTimes = () => {
      if (props.userData.break_times && props.userData.break_times.length > 0) {
        return props.userData.break_times
      }

      return 0
    }

    return {
      resolveUserVerified,
      resolveUserStatusVariant,
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      userBreakTimes,
      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
