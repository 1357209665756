import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useUsersList() {
  const userListTable = ref([])

  const tableColumns = [
    { text: 'USER', value: 'fullName' },
    { text: 'PHONE NUMBER', value: 'mobile_number', sortable: false },
    { text: 'Date of joining', value: 'created_at', sortable: false },
    { text: 'Verified', value: 'is_verified' },

    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const phoneFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const userTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchUsers = () => {
    options.value.q = searchQuery.value
    store
      .dispatch('app-user/fetchUsers', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
        phone: phoneFilter.value,
        plan: planFilter.value,
      })
      .then(response => {
        const { filteredData, total, userTotal } = response.data.data
        userListTable.value = filteredData
        totalUserListTable.value = total
        userTotalLocal.value = userTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, phoneFilter, planFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchUsers()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // As we changes this field to phone number instead of Role; this function will no longer be needed
  // *===============================================---*

  const resolveUserVerified = status => {
    if (status === 0) return 'No'
    if (status === 1) return 'Yes'

    return 'No'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'

    return 'warning'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    userListTable,
    tableColumns,
    searchQuery,
    phoneFilter,
    planFilter,
    statusFilter,
    totalUserListTable,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    fetchUsers,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
    resolveUserVerified,
  }
}
